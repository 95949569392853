<template>
  <div class="ad-container">
    <ins class="eas6a97888e2" :data-zoneid="zoneIdMobile" :data-sub="subId"></ins>
  </div>
</template>

<script>
export default {
  name: 'Advertisement',

  props: {
    subId: {
      required: true,
    }
  },

  data() {
    return {
      zoneIdMobile: '5518144',
      // zoneIdDesktop: '5518146',

      // USAR ESTAS PARA LOCAL
      // zoneIdMobile: '5517286',
      // zoneIdDesktop: '5517862',
      showAd: false
    }
  },

  computed: {
    zoneId() {
      return window.innerWidth <= 768 ? this.zoneIdMobile : this.zoneIdDesktop;
    }
  },

  mounted() {
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://a.magsrv.com/ad-provider.js'
    document.head.appendChild(script)

    script.onload = () => {
      window.AdProvider = window.AdProvider || []
      window.AdProvider.push({ "serve": {} })

      // Esperar a que el div se cargue y luego aplicar estilos
      setTimeout(() => {
        const adContainer = document.querySelector('.ad-container');
        const adDiv = adContainer.querySelector('div');
        const adDivMaster = adDiv.querySelector('div');
        if (adDiv) {
          adDiv.style.maxWidth = '100%';
          adDiv.style.width = '100%';
          adDiv.style.boxSizing = 'border-box';
          adDiv.style.margin = 'auto';
          adDiv.style.height = 'auto';

          adDivMaster.style.maxWidth = '100%';
          adDivMaster.style.width = '100%';
          adDivMaster.style.boxSizing = 'border-box';
          adDivMaster.style.margin = 'auto';
          adDivMaster.style.maxheight = '300x';
          adDivMaster.style.height = 'auto';

          // Crear y agregar el párrafo <p> dinámicamente
          const adLabel = document.createElement('p');
          adLabel.className = 'ad-label';
          adLabel.textContent = 'Ad';
          adLabel.style.color = 'white';
          adLabel.style.fontSize = '13px';
          adDivMaster.appendChild(adLabel);
        }
      }, 3000); // Ajusta el tiempo de espera según sea necesario
    }
  },

  beforeDestroy() {
    const script = document.querySelector('script[src="https://a.magsrv.com/ad-provider.js"]')
    if (script) {
      document.head.removeChild(script)
    }
  },
}
</script>

<style scoped>
.ad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 728px;
  margin: auto;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.ad-container>div {
  max-width: 100% !important;
  width: 100% !important;
  height: auto !important;
  box-sizing: border-box !important;
}

.ad-label {
  color: white !important;
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .ad-container {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0;
    text-align: left;
    position: relative;
  }

  .ad-container>div {
    max-width: 100% !important;
    width: 100% !important;
    height: auto !important;
    box-sizing: border-box !important;
  }

  .ad-label {
    text-align: center;
    margin-top: 4px;
  }
}
</style>