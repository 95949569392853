<template>
    <v-dialog transition="dialog-top-transition" max-width="600" v-model="showDialog">
        <v-card>
            <v-card-text class="py-0 px-0">
                <v-form ref="formEditUser" lazy-validation>
                    <v-alert border="right" colored-border color="#8e84c0" elevation="0"
                        class="col-12 px-10 py-10 pb-4 mb-0">
                        <v-col class="px-1 pt-0 m-0 text-left">
                            <v-row no-gutters>
                                <v-col cols="12" class="px-0 pt-0 mt-0 text-left">

                                    <span class="title-emails">
                                        Enviando email a {{ emailSend }}
                                    </span>
                                    <br>
                                    <span class="">
                                        Se enviará un email a este usuario
                                    </span>
                                    <v-divider class="mt-2"></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <div class="text-left">

                            <v-text-field id="subject" v-model="subject" label="Asunto" outlined clearable
                                color="#8e84c0" class="mb-0" :rules="[rules.required]">
                            </v-text-field>

                            <v-textarea outlined v-model="message" label="Mensaje" color="#8e84c0"
                                :rules="[rules.required]" class="col-12 col-md-6">
                            </v-textarea>

                        </div>
                        <div class="text-right">
                            <v-btn :disabled="!isFormValidMail" @click="sendToEmail()" :loading="saveLoading"
                                color="#8e84c0" class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                                <v-icon left color="white">mdi-email-fast-outline</v-icon>
                            Enviar
                            </v-btn>
                        </div>
                    </v-alert>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script>
import axios from '@/axios.js';
export default {
    props: {
        dialogShow: {
            type: Boolean,
            required: true,
        },
        emailSend: {
            type: String,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            editDialogMail: false,
            subject: '',
            message: '',
            saveLoading: false,
            rules: {
                required: (value) => !!value || 'Este campo es requerido',
            },
        }
    },
    computed: {
        isFormValidMail() {
        return this.subject && this.message;
        },
        showDialog: {
            get() {
                return this.dialogShow;
            },
            set(value) {
                this.$emit("update:dialogShow", value);
            },
        },
    },
    methods: {
        sendToEmail() {
            this.saveLoading = true;
            axios.post('/admin/send-email', { userId: this.userId, subject: this.subject, message: this.message })
                .then((response) => {
                    if (response.data.success) {
                        this.$vs.notify({
                            title: 'Enviado',
                            text: 'Email enviado correctamente',
                            color: 'success',
                            icon: 'check_box',
                            position: 'bottom-center',
                            time: 5000,
                        });
                        this.showDialog = false;
                        this.subject = '';
                        this.message = '';
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.$vs.notify({
                        title: 'Error',
                        text: 'No se ha podido enviar el email',
                        color: 'danger',
                        icon: 'warning',
                        position: 'bottom-center',
                        time: 5000,
                    });
                })
                .finally(() => {
                    this.saveLoading = false;
                });
        },
    }
}

</script>



<style scoped>

.title-emails {
  color: #8061c2;
  font-weight: 700;
  font-size: 20px;
}

.harmony-name {
  color: #8061c2;
  font-weight: 800;
  font-size: 18px;
}
</style>


