<template>
    <!-- Modal para editar usuario -->
    <v-dialog transition="dialog-top-transition" max-width="600" v-model="showDialog">
        <v-card class="p-0 m-0">
            <v-alert :value="true" border="right" colored-border color="#8e84c0" elevation="0" class="pl-4 pr-5 mb-0">
                <v-col class="px-1 pt-0 m-0 text-left">
                    <v-row no-gutters>
                        <v-col cols="12" class="px-0 pt-0 mt-0 text-left">

                            <span class="title-emails">
                                Editando monetización de {{ harmonyName }}
                            </span>
                             <br>
                            <span class="">
                                Modifica la monetización de este harmony
                            </span>

                            <v-divider class="mt-2"></v-divider>

                            <span class="harmony-name">
                                {{ }}
                            </span>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <div class="">
                        <v-select v-model="localHarmonyTypeMonetization" :items="typesMonetization" item-text="text"
                            item-value="value" 
                            label="Método de Monetización"
                            chips
                            multiple
                            hint="Selecciona los métodos de monetización que puede usar el harmony"
                            persistent-hint
                            color="#8061c2"
                            chip-color="#8061c2"
                             @change="handleMonetizationChange"
                            >
                        </v-select>
                    </div>

                    <v-row no-gutters>

                        <v-checkbox
                            v-model="localAds"
                            color="#8061c2"
                            class="pt-5"
                            :label="`Publicidad:`"
                        ></v-checkbox>

                        <v-chip
                        class="mt-7 ml-5"
                        color="#9b9b9b"
                        text-color="white"
                        v-if="!localAds"
                        >
                        Inactiva
                        </v-chip>

                        <v-chip
                        class="mt-7 ml-5"
                        color="green"
                        text-color="white"
                        v-else
                        >
                        Activa
                        </v-chip>
                    </v-row>

                    <div class="pt-1">
                        <v-select v-model="selectedLinks" :items="linksHarmony" item-text="name"
                            item-value="url" label="Links monetizables" chips multiple
                            hint="Selecciona los links que pueden ser monetizados."
                            persistent-hint
                            color="#8061c2"
                            chip-color="#8061c2"
                            :disabled="disabledLinks"
                            @change="handleLinksChange"
                            >
                            <template v-slot:item="data">
                                <v-list-item v-bind="data.attrs" v-on="data.on">

                                    <v-list-item-action>
                                        <v-icon>mdi-link</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ data.item.url.substring(0, 45)
                                            }}...</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </div>
                </v-col>

                <div class="text-center">
                    <v-btn @click="updatedMonetization()" color="#8e84c0"
                        class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                        <v-icon left color="white">mdi-cash-sync</v-icon>
                        Actualizar
                    </v-btn>
                </div>
            </v-alert>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    name: 'MonetizationEdit',
    props: {
        idHarmony: {
            type: Number,
        },
        harmonyTypeMonetization: Array,
        linksHarmony: Array,
        linksHarmonyMonetizable: Array,
        typesMonetization: Array,
        harmonyName: {
            type: String,
            required: true,
        },
        dialogShow: {
            type: Boolean,
            required: true,
        },
        ads: {
            required: true
        }
    },

    data() {
        return {
            localHarmonyTypeMonetization: [],
            localLinksHarmonyMonetizable: [],
            selectedLinks: [],
            disabledLinks: false,
            localAds: this.ads
        }
    },


    watch: {
        harmonyTypeMonetization: {
            immediate: true,
            handler(newVal) {
                this.localHarmonyTypeMonetization = newVal;
                this.handleMonetizationChange();
            }
        },
        linksHarmonyMonetizable: {
            immediate: true,
            handler(newVal) {
                this.selectedLinks = newVal.map(link => link.url);
                this.localLinksHarmonyMonetizable = newVal;
            }
        },
        ads: {
            immediate: true,
            handler(newVal) {
                this.localAds = newVal;
            }
        },
        idHarmony: {
            immediate: true,
            handler() {
                this.resetLocalData();
            }
        },
        dialogShow: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.resetLocalData();
                }
            }
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.dialogShow;
            },
            set(value) {
                this.$emit("update:dialogShow", value);
            },
        },
    },
    created (){
    },
    methods: {
        updatedMonetization() {
            this.$emit('update-monetization', this.localHarmonyTypeMonetization, this.localLinksHarmonyMonetizable, this.idHarmony, this.localAds);
        },
        handleMonetizationChange() {
            if (this.localHarmonyTypeMonetization.includes('no_monetize')) {
                this.localHarmonyTypeMonetization = ['no_monetize'];
            }

            // Si no se puede monetizar por clicks únicos, se deshabilitan los links
            this.disabledLinks = this.localHarmonyTypeMonetization.includes('no_monetize') || !this.localHarmonyTypeMonetization.includes('unique_clicks');
        },
        handleLinksChange(selectedUrls) {
        this.localLinksHarmonyMonetizable= this.linksHarmony.filter(link => selectedUrls.includes(link.url));
        },

        resetLocalData() {
            this.localHarmonyTypeMonetization = this.harmonyTypeMonetization;
            this.selectedLinks = this.linksHarmonyMonetizable.map(link => link.url);
            this.localLinksHarmonyMonetizable = this.linksHarmonyMonetizable;
            this.localAds = this.ads;
        }
    }
};
</script>

<style scoped>
.title-emails {
  color: #8061c2;
  font-weight: 700;
  font-size: 20px;
}

.harmony-name {
  color: #8061c2;
  font-weight: 800;
  font-size: 18px;
}
</style>