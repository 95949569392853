<template>
    <v-container class="my-10 my-md-16">
        <v-row class="d-flex flex-row pa-3 heading">
            <v-col class="col-12 col-md-6">
                <h1 class="text-left">{{ $t('panelHome.ads')  }}</h1>
                <p class="text-left">
                    {{ $t('panelAds.description') }}
                </p>
            </v-col>
            <v-col class="col-12 col-md-6">
                <v-row no-gutters>
                    <v-checkbox
                        v-model="ads"
                        color="#8061c2"
                        class="pt-5"
                        :label="`Publicidad:`"
                        @change="changeMonetization"
                    ></v-checkbox>

                    <v-chip
                    class="mt-7 ml-4 mr-9"
                    color="#9b9b9b"
                    text-color="white"
                    v-if="!ads"
                    >
                    Inactiva
                    </v-chip>

                    <v-chip
                    class="mt-7 ml-4 mr-9"
                    color="green"
                    text-color="white"
                    v-else
                    >
                    Activa
                    </v-chip>

                    <v-autocomplete v-model="selectedSite" :items="siteNames" :label="$t('Statistics.label')"
                    @change="setSiteData(selectedSite)"  color="#9BA9BD" class="mb-4 mt-6"></v-autocomplete>
                </v-row>

            </v-col>
        </v-row>

        <v-row class="d-block d-md-flex" v-if="site">
            <v-col class="col-12 col-md-4">
                <div
                    class="profile d-flex text-left justify-space-around justify-md-center align-center px-5 py-6 rounded-xl fill-height">
                    <div class="d-flex flex-column justify-between">
                        <h4 class="wrap text-capitalize">{{ selectedSite }}</h4>
                        <p class="wrap">{{ this.site.description }}</p>
                    </div>
                    <img :src="site.logo_path ? site.logo_path : require('../assets/illustrations/no-photo.jpg')"
                        alt="" width="100" height="100" class="rounded-pill" />
                </div>
            </v-col>
            <v-col class="col-12 col-md-8">
                <div class="profile d-flex text-left justify-center align-center  rounded-xl fill-height py-2 px-2">
                    <v-row class="fill-height  justify-space-around justify-md-space-around px-3 py-6">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-container class="m-w mt-0  pa-md-0">
                                        <h4 class="number">{{ formatNumber(0) }}</h4>
                                        <p>Impresiones</p>
                                        <v-progress-circular
                                        v-if="loading"
                                        indeterminate
                                        color="rgb(151, 138, 206)"
                                        class="mt-4"
                                        />
                                    </v-container>
                                    <v-icon v-if="!loading" class="icon pa-3 pa-md-0" size="40">mdi-eye-check</v-icon>
                                </v-col>
                            </template>
                            <span>Impresiones</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-container class="m-w mt-0  pa-md-0">
                                        <h4 class="number">{{ formatNumber(0) }}</h4>
                                        <p>Conversiones</p>
                                        <v-progress-circular
                                        v-if="loading"
                                        indeterminate
                                        color="rgb(151, 138, 206)"
                                        class="mt-4"
                                        />
                                    </v-container>
                                    <v-icon v-if="!loading" class="icon pa-3 pa-md-0" size="40">mdi-account-star</v-icon>
                                </v-col>
                            </template>
                            <span>Conversiones</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-container class="m-w mt-0  pa-md-0">
                                        <h4 class="number">{{ formatNumber(0) }}</h4>
                                        <p>Clics</p>
                                        <v-progress-circular
                                        v-if="loading"
                                        indeterminate
                                        color="rgb(151, 138, 206)"
                                        class="mt-4"
                                        />
                                    </v-container>
                                    <v-icon v-if="!loading" class="icon pa-3 pa-md-0" size="40">mdi-cursor-default-click</v-icon>
                                </v-col>
                            </template>
                            <span>Clics</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-container class="m-w mt-0  pa-md-0 m-auto">
                                        <h4 class="number">
                                            ${{ 0 }}
                                        </h4>
                                        <p>{{ $t('Statistics.totalEarnings') }}</p>
                                        <v-progress-circular
                                        v-if="loading"
                                        indeterminate
                                        color="rgb(151, 138, 206)"
                                        class="mt-4"
                                        />
                                    </v-container>
                                    <v-icon v-if="!loading" size="40" class="icon pa-3  pa-md-0">mdi-cash-multiple</v-icon>
                                </v-col>
                            </template>
                            <span>{{ $t('Statistics.tooltip-TotalEarnings') }}</span>
                        </v-tooltip>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <center class="mt-15">
            <p class="h1 mt-15">Gráfica disponible muy pronto...</p>
        </center>

    </v-container>

</template>


<script>
import axios from "@/axios.js";
import "chartjs-plugin-colorschemes";

export default {
    name: "Ads",
    metaInfo: {
        titleTemplate: '%s - Harmony Fan',
        title: 'Publicidad'
    },
    data() {
        return {
            sites: [],
            site: [],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#E57373", "#F06292", "#BA68C8", "#64B5F6", "#4FC3F7", "#81C784", "#FFD54F"],
            selectedSite: null,
            ads: false,
            loading: false,
            selectedMonth: this.formatToYearMonth(new Date()),
            menu: false,
            lang: "es",
        };
    },

    created() {
        this.$i18n.locale = 'es';
    },

    computed: {
        siteNames() {
            return this.sites.map((site) => site.name);
        },
    },

    watch: {
        "$i18n.locale": function () {
            this.checkLanguage()
        }
    },

    methods: {

        formatDecimal(value) {
            // Verificar si el valor tiene más de dos decimales
            const parts = value.toString().split('.');

            if (parts.length === 2 && parts[1].length > 2) {
            // Si tiene más de dos decimales, cortarlo a los primeros dos sin redondear
            return parseFloat(parts[0] + '.' + parts[1].slice(0, 2));
            }

            // Si tiene dos o menos decimales, devolver el valor original
            return value;
        },

        showSelectedRange() {
            this.fetchSites();
        },

        getTotEarning(value){
            return value.toFixed(2)
        },

        formatCurrency(value) {
            return new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'USD',
            }).format(value);
        },

        formatDate(value) {
            return new Date(value).toLocaleDateString('es-ES');
        },

        formatPercentage(value) {
            return `${(value * 100).toFixed(0)}%`;
        },

        checkLanguage() {
            const storedLocale = localStorage.getItem("locale");
            this.lang = storedLocale;
        },

        setSiteData(siteName) {
            this.site = this.sites.find(site => site.name === siteName);
            if (this.site) {
                this.selectedSite = siteName;
                this.currentSite = this.site;
                this.ads = this.site.monetization?.ads || 0;
            }
        },

        async fetchSites() {
            this.loading = true
            try {
                const response = await axios.get("get-sites-monetizations", {
                    params: {}
                });
                this.sites = response.data;

                if (this.sites.length > 0) {
                    if (this.selectedSite == null) {
                        this.selectedSite = this.sites[0].name;
                        this.setSiteData(this.selectedSite)
                    }
                }
                this.loading = false
            } catch (error) {
                this.loading = false
                console.error("Error fetching sites:", error);
            }
        },

        async changeMonetization() {
            this.loading = true
            try {
                const response = await axios.post("/admin/set-site-monetization", {site: this.site.id, ad: this.ads});

                this.sites = response.data;

                this.$vs.notify({
                    title: 'Éxito',
                    text: 'Publicidad actualizada correctamente',
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                });

                this.loading = false
            } catch (error) {
                this.loading = false
                console.error("Error fetching sites:", error);
            }
        },

        truncateEmail(email) {
            const [localPart, domain] = email.split('@');
            let truncatedLocalPart;
            if (localPart.length >= 3) {
                truncatedLocalPart = localPart.slice(0, 3) + '...';
            } else {
                truncatedLocalPart = localPart;
            }
            return `${truncatedLocalPart}@${domain}`;
        },

        formatNumber(value) {
            if (value >= 1000000) {
                return (value / 1000000).toFixed(1) + 'M';
            } else if (value >= 1000) {
                return (value / 1000).toFixed(1) + 'k';
            } else {
                return value;
            }
        },
        formatToYearMonth(date) {
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;

        },
    },

    mounted() {
        this.fetchSites();
    },
};
</script>

<style lang="scss" scoped>

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    padding: 0 4px !important;
}
</style>

<style scoped>
.v-container {
    max-width: 1200px;
    margin: 0 auto;
}
.tooltip-container {
    position: absolute;
    top: 10px;
    right: 13px;
}
.scroll-data {
    height: 272px;
    overflow-y: auto;
}

.round-tab {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.custom-tab {
    text-transform: none;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 16px;
}

.m-w {
    width: 100%;
    margin: 0;
    text-align: center
}

.heading h1 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.heading p {
    font-size: 0.8rem;
    color: rgb(151, 138, 206);
    font-weight: 600;
}

.profile,
.chart {
    gap: 20px;
    color: rgb(70, 70, 70);
    -webkit-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    -moz-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    overflow-y: hidden;
    overflow-x: hidden;
}

.profile p {
    font-size: 0.7rem;
    max-width: 250px;
    margin: auto;
}

.profile img {
    font-size: 0.8rem;
    object-fit: cover;
}
.profile {
    position: relative !important;
}

.number {
    font-size: 1.8rem;
    line-height: 1.2;

}

.icon {
    color: rgb(151, 138, 206);
}

.list {
    list-style: none;
    padding: 0;
    font-size: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.chartjs-render-monitor {
    max-height: 350px !important;
}

.color-box {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    border-radius: 2px;
    margin-bottom: 2px;
}

.chip-container {
    display: flex;
    flex-wrap: wrap;  /* Permite que se dividan en múltiples filas */
    max-height: 240px; /* Ajusta esta altura si es necesario */
    overflow-x: auto;  /* Scroll horizontal */
    background-color: #f4e8ff;
    border-radius: 12px;
}

.flags {
    width: 21px;
}

.arrow {
    transform: rotate(90deg);
    padding: 0 !important;
    cursor: pointer;
    margin-bottom: 5px;
    border-radius: 50%;
    background-color: #c9b2ff;
    color: #8a72c1;
}

.actived-arrow {
    background-color: rgb(223, 223, 223);
    color: rgb(170, 170, 170);
}

.v-card {
    box-shadow: none !important;
    width: 100% !important;
    overflow: auto;
}

.show {
    margin-left: 5px;
    font-size: 0.7rem;
}

.wrap {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu {
    width: 200px !important;
}
</style>